import "normalize.css";
import * as THREE from "three";
import {
  OrbitControls
} from "three/examples/jsm/controls/OrbitControls.js";
import {
  RGBELoader
} from "three/examples/jsm/loaders/RGBELoader.js";
import {
  GLTFLoader
} from "three/examples/jsm/loaders/GLTFLoader.js";

const hdrTextureURL = new URL("../assets/img/photostudionight.hdr",
  import.meta.url);
const bullet3D = new URL("../assets/3d/organic.glb",
  import.meta.url);
const roughnessTextureURL = new URL(
  "../assets/img/roughness/roughness0.jpg",
  import.meta.url
);
// const textureLoaderURL = new URL(
//   "../assets/img/color/texturecolor.jpg",
//   import.meta.url
// );
// const normalTextureURL = new URL(
//   "../assets/img/normal/texturenormal.jpg",
//   import.meta.url
// );
// const bumpTexture = new THREE.TextureLoader().load("../assets/img/bump/texturebump.jpg");
// const displacementTexture = new THREE.TextureLoader().load("../assets/img/displacement/texturedisplacement.jpg");

let backgroundColor = 0x000000; // 0x000000 = black; 0xffffff = white;
let assetColor = 0x1d00e7 // 0xffffff // 0xc6c6c6; // 0xf7d000 = gold
let model;
let modelSize = 8;

// _____________________ NORMAL MAP __________________________
// const normalTextureLoader = new THREE.TextureLoader();
// let normalTexture;
// normalTextureLoader.load(normalTextureURL, function (loadedNormalTexture) {
//   normalTexture = loadedNormalTexture;
//   normalTexture.encoding = THREE.sRGBEncoding;
//   createModel();
// });

// _____________________ COLOR MAP __________________________
// const textureLoader = new THREE.TextureLoader();
// let colorTexture;
// textureLoader.load(textureLoaderURL, function (loadedTexture) {
//   colorTexture = loadedTexture;
//   texture.encoding = THREE.sRGBEncoding;
//   createModel();
// });

// _____________________ ROUGNESS MAP __________________________
const roughnessLoader = new THREE.TextureLoader();
let roughnessTexture;
roughnessLoader.load(roughnessTextureURL, function (texture) {
  roughnessTexture = texture;
  roughnessTexture.encoding = THREE.sRGBEncoding;
  // You can adjust the texture properties here if needed.
  // Example: roughnessTexture.wrapS = roughnessTexture.wrapT = THREE.RepeatWrapping;
  // Example: roughnessTexture.repeat.set(2, 2);
  //   roughnessTexture.anisotropy = 16;
  loadGLTFModel();
});

// _____________________ RENDER SCENE SETTINGS __________________________
const renderer = new THREE.WebGLRenderer({
  antialias: true
});
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

// _____________________ Sets orbit control to move the camera around __________________________
const orbit = new OrbitControls(camera, renderer.domElement);
orbit.enableDamping = true;
orbit.dampingFactor = 0.02;
orbit.screenSpacePanning = false;
// orbit.enableZoom = false;
orbit.minDistance = 8;
orbit.maxDistance = 18;
orbit.enablePan = false;

let isMouseUp = true;
let cameraVelocity = new THREE.Vector3(); // Initialize camera velocity

// ___________ Listen for mouse down and up events to control the easing effect
document.addEventListener("mousedown", () => {
  isMouseUp = false;
});

document.addEventListener("mouseup", () => {
  isMouseUp = true;
});

// ___________ Animate camera with easing effect
function animateCamera() {
  if (!isMouseUp) {
    // ___________ While the mouse button is pressed, reset camera velocity
    cameraVelocity.set(0, 0, 0);
  } else {
    // ___________ When the mouse button is released, apply an easing effect
    const dampingFactor = 1.2; // Adjust this value for the desired easing strength
    cameraVelocity.multiplyScalar(dampingFactor);
  }

  orbit.update(); // Update OrbitControls
  camera.position.add(cameraVelocity); // Update camera position based on velocity

  requestAnimationFrame(animateCamera);
}

animateCamera();

// _____________________ Camera positioning __________________________
camera.position.set(0, 0, 10);
orbit.update();

// _____________________ HDRI SETTINGS __________________________
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 2;

// _____________________ HDRI LOADER __________________________
const loader = new RGBELoader();
loader.load(hdrTextureURL, function (texture) {
  texture.mapping = THREE.EquirectangularReflectionMapping;
  // scene.background = texture; // si on veut avoir un fond de couleur sans l'image
  scene.environment = texture;
  //   const sphere = new THREE.Mesh(
  //     new THREE.SphereGeometry(1, 50, 50),
  //     new THREE.MeshStandardMaterial({
  //       roughness: 0,
  //       metalness: 0.5,
  //       color: 0xf7d000,
  //     })
  //   );
  // scene.add(sphere);
  // _____________________ LOGO __________________________
  const assetLoader = new GLTFLoader();
  assetLoader.load(
    bullet3D.href,
    function (gltf) {
      model = gltf.scene;

      // ___________ Apply the roughness texture to the model's material
      model.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = new THREE.MeshStandardMaterial({
            // map: colorTexture,
            color: assetColor,
            roughnessMap: roughnessTexture,
            // roughness: 0.1,
            // normalMap: normalTexture,
            // bumpMap: bumpTexture,
            // displacementMap: displacementTexture,
            metalness: 0.9,
          });
        }
      });

      scene.add(model);
      model.position.set(0, -1, 0);
      model.rotation.set(0, 5, 0);
      model.scale.set(modelSize, modelSize, modelSize);
    },
    undefined,
    function (error) {
      console.log(error);
    }
  );
});

// _____________________ COULEUR DE FOND UNIFORME _________________________
renderer.setClearColor(backgroundColor);

// _____________________ GRID HELPER _________________________
// const gridHelper = new THREE.GridHelper(12, 12);
// scene.add(gridHelper);

// _____________________ Sets the x, y, and z axes with each having a length of 4
// const axesHelper = new THREE.AxesHelper(4);
// scene.add(axesHelper);

function animate() {
  if (model) {
    model.rotation.y += 0.0003;
    // model.rotation.x += 0.0001;
  }
  renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener("resize", function () {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});